.modalWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
  padding:1.5rem;
}

.modalContent {
  margin:0 auto;
  max-width: 670px;
  padding: 1.5rem;
  img {
    margin:auto;
    @extend .shadow-default;
  }
  .btn-warning {
    @extend .shadow-default;
  }

  .close-image {
    z-index: 1;
    border-radius: 50%;
    width:50px;
    height: 50px;
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    text-align: center;
    display: flex;
    cursor: pointer;

    > .icofont-close {
      margin: auto;
      display: flex;
      align-items: center;
    }
  }

  &.image {
    .close-image {
      background-color: #FFFFFF;
      @extend .shadow-default;
    }
  }

  &.account {
    background-color: #FFFFFF;
    @extend .shadow-default;
    padding:3rem 1.5rem;
    min-width: 270px;
    @media screen and (max-width: 320px) {
      min-width: auto;
    }
    .close-image {
      top:-3rem;
    }
    .acontainer {
      margin: auto;
    }
  }
}



