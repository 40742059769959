// GLOBAL

body {
  font-family: $raleway;
  font-weight: 500;
  background-color: #e8e8e8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.noScroll {
    overflow-y: hidden;
  }
}

.pageWrapper {
  max-width: 720px;
  margin: 0 auto;
  position: relative;
}

.contentWrapper {
  background-color: #ffffff;
  min-height: calc(100vh + 3.4375rem);
  div.mainContent {
    padding: 1.5rem 1.5rem 6.4375rem 1.5rem;

    &.paddingOnlyLeft {
      padding: 0 0 0 25px;
    }

    &.resetPaddingX {
      padding: 0 0 3.4375rem 0;
    }

    &.noPadding {
      padding: 0;
    }
  }
}

.contentFooter {
  background-color: $col-lightblue;
  padding: 0.75rem 0 3.4375rem 0;
}

.rich-text {
  p {
    margin-bottom: 1rem;
  }
  a {
    color: $col-darkblue;
    text-decoration: underline;
  }
}

p {
  font-size: 1rem;
}

.is-caption {
  position: relative;
  .img-caption {
    position: absolute;
    bottom: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    padding: 0.25rem;
    > * {
      font-size: 0.8125rem;
      color: #ffffff;
    }
  }
}

h1 {
  &:not(.no-style) {
    color: $col-darkblue;
    font-size: 20px;
    font-weight: 700;
    padding-left: 25px;
    position: relative;
    min-height: 66px;
    padding: 17px 25px;
    border-bottom: 1px solid $col-lightgrey;

    &:not(.goBack):before {
      background-color: $col-darkblue;
      height: 1px;
      width: 15px;
      content: ' ';
      display: block;
      position: absolute;
      top: 31px;
      left: 0px;
    }

    .goBackArrow {
      margin-right: 18px;
      position: relative;
      top: -2px;
      cursor: pointer;
    }

    .subpage {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

h2 {
  color: $col-darkblue;
  font-size: 1.125rem;
  font-weight: 900;
  position: relative;
  margin-top: 20px;
  margin-bottom: 15px;

  &:before {
    background-color: $col-darkblue;
    height: 10px;
    width: 15px;
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: -1.5rem;
  }
}
