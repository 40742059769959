.menu {
  .menuList {
    .menuListItem {
      border-bottom: 1px solid $col-lightgrey;
      &:last-child {
        border-color: transparent;
      }
    }
  }
}
