$font-path: '../../fonts/' !default;
$icofont-path: '../../fonts/icons/' !default;

$raleway: 'raleway', sans-serif !default;
$icofont-family: 'icofont' !default;

$font-size-default: 1.4rem;

$col-lightgrey : #ededed;
$col-midgrey : #d9dce1;
$col-darkgrey : #2f2f2f;
$col-darkblue : #002663;
$col-lightblue : #f2f5fa;
$col-green : #bed600;
$col-red : #ac1a2f;

$pretty--class-name: pretty;
$pretty--color-default:#bdc3c7;
$pretty--color-primary:$col-darkblue;
$pretty--color-info:#5bc0de;
$pretty--color-success:$col-green;
$pretty--color-warning:#f0ad4e;
$pretty--color-danger:$col-red;
$pretty--color-dark:#5a656b;
$pretty--z-index-back:0;
$pretty--z-index-between:1;
$pretty--z-index-front:2;

$icofont-lock: "\ef7a";
$icofont-info-circle: "\ef4e";
$icofont-exclamation-circle: "\ef19";
$icofont-institution: "\ef51";
$icofont-home: "\ef47";
$icofont-close: "\eee4";
$icofont-navigation-menu: "\efa2";
$icofont-list: "\ef72";
$icofont-list-alt: "\e900";
$icofont-exclamation-tringle: "\ef1b";
$icofont-location-pin: "\ef79";
$icofont-focus: "\ef34";
$icofont-check: "\eed8";
$icofont-hour-glass: "\ef49";
$icofont-simple-up: "\eab9";
$icofont-simple-down: '\eab2';
$icofont-simple-right: "\eab8";
$icofont-simple-left: "\eab5";
$icofont-link: "\ef71";
$icofont-envelope: "\ef14";
$icofont-phone: "\efbb";
$icofont-alarm: "\eea3";
$icofont-settings: "\efe2";
$icofont-settings-alt: "\efe1";
$icofont-plus: "\efc2";
$icofont-options: "\efb0";
$icofont-tools-alt-2: "\f013";

