.pretty {
    white-space: normal;
    width: 100%;
}

.pretty .state label {
    color:$col-darkblue;
    font-weight:500;
    width:100%; 
    font-family:$raleway;
    text-indent: 0;
    padding-left: 2rem;
}

.pretty .state label:after,
.pretty .state label:before,
.pretty.p-icon .state .icon 
{
  top: -2px;
}

.pretty .state label::before {
  border-color:$col-darkblue;
}

