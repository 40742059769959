.form-group {
  margin-bottom: 1.25rem;
  &.map {
    margin-bottom:3rem;
  }
}

.help-block {
  color:$col-red;
  font-weight:bold;
  padding-top: 5px;
}

.error {
  color:$col-red;
  text-align:center;
  font-weight:bold;
  margin-bottom: 1.25rem;

}

.success {
  @extend .error;
  color:$col-green;
}

