footer.mainFooter {
  display: block;
  background: #FFFFFF;
  height: 55px;
  border-top: 2px solid $col-lightgrey;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  max-width: 720px;
  .linkItem {
    text-align: center;
    height: 55px;

    a {
      text-transform: uppercase;
      height: 100%;
      font-size: 9px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $col-darkgrey;
      box-sizing: border-box;

      &.current {
        border-top: 5px solid $col-green;

        > div {
          top: -2px;
          position: relative;
        }
      }
    }
  }
}
