.inputDefaultTheme {
  padding:.75rem;
  background-color: $col-lightblue;
  box-sizing: border-box;
  border: 0;
  border-bottom: 2px solid $col-darkblue;
  width: 100%;

  &::placeholder {
    color: $col-darkblue;
    font-weight: 500;
    font-size: 1rem;
  }   
}

#inputDescription {
  resize: none;
  margin-top: 50px;
  padding: .75rem;
}

#inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    @extend .btn-default
  }

  .inputfile:focus + label,
  .inputfile + label:hover {
    background-color: red;
  }
}

#inputDescription,
#inputLocation,
#inputNom,
#inputPrenom {
  background-color: $col-lightblue;
  box-sizing: border-box;
  border: 0;
  border-bottom: 2px solid $col-darkblue;
  width: 100%;
  margin-bottom: 0;
  margin-top: 25px;

  &::placeholder {
    color: $col-darkblue;
    font-weight: 600;
    font-size: 1rem;
  }

  &:focus {
    outline: 0;
  }
}

#inputLocation {
  margin-bottom: 0;
  padding: 1rem .75rem;
  font-size: 1rem;
  font-weight: 500;

  &::placeholder {
    color: $col-darkblue;
    font-weight: 500;
    color: #002663;
    font-weight: 600;
    font-size: 1rem;
  }
}

.input_img {
  width: 50%;
  flex: auto;
  margin-bottom: 10px;
}

.suggestLocalisation {
  background-color: $col-lightblue;
  box-sizing: border-box;
  list-style-type: none;

  li {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid $col-midgrey;
    color: $col-darkblue;
    font-size: 1rem;
    padding-left: 7px;
    overflow: hidden;
    font-weight: 500;

    &#boutonGeoloc {
      border-left: 3px solid $col-green;
      position: relative;

      > img {
        position: absolute;
        top: 15px;
        right: 5px;
      }
    }
  }
}
