@font-face {
  font-family: "#{$icofont-family}";
  src: url("#{$icofont-path}/#{$icofont-family}.eot?p0z80e");
  src: url("#{$icofont-path}/#{$icofont-family}.eot?p0z80e#iefix")
      format("embedded-opentype"),
    url("#{$icofont-path}/#{$icofont-family}.ttf?p0z80e") format("truetype"),
    url("#{$icofont-path}/#{$icofont-family}.woff?p0z80e") format("woff"),
    url("#{$icofont-path}/#{$icofont-family}.woff2?p0z80e") format("woff2"),
    url("#{$icofont-path}/#{$icofont-family}.svg?p0z80e#{$icofont-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: inline-block;
}

.icofont {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icofont-family}" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icofont"],
[class*=" icofont-"],
[class*="icofont-"] {
  @extend .icofont;
}

.icofont-list-alt {
  &:before {
    content: $icofont-list-alt;
  }
}
.icofont-lock {
  &:before {
    content: $icofont-lock;
  }
}
.icofont-info-circle {
  &:before {
    content: $icofont-info-circle;
  }
}
.icofont-exclamation-circle {
  &:before {
    content: $icofont-exclamation-circle;
  }
}
.icofont-institution {
  &:before {
    content: $icofont-institution;
  }
}
.icofont-home {
  &:before {
    content: $icofont-home;
  }
}
.icofont-close {
  &:before {
    content: $icofont-close;
  }
}
.icofont-navigation-menu {
  &:before {
    content: $icofont-navigation-menu;
  }
}
.icofont-list {
  &:before {
    content: $icofont-list;
  }
}
.icofont-exclamation-tringle {
  &:before {
    content: $icofont-exclamation-tringle;
  }
}
.icofont-location-pin {
  &:before {
    content: $icofont-location-pin;
  }
}
.icofont-focus {
  &:before {
    content: $icofont-focus;
  }
}
.icofont-check {
  &:before {
    content: $icofont-check;
  }
}
.icofont-hour-glass {
  &:before {
    content: $icofont-hour-glass;
  }
}
.icofont-simple-up {
  &:before {
    content: $icofont-simple-up;
  }
}
.icofont-simple-down {
  &:before {
    content: $icofont-simple-down;
  }
}
.icofont-simple-right {
  &:before {
    content: $icofont-simple-right;
  }
}
.icofont-simple-left {
  &:before {
    content: $icofont-simple-left;
  }
}
.icofont-link {
  &:before {
    content: $icofont-link;
  }
}
.icofont-envelope {
  &:before {
    content: $icofont-envelope;
  }
}
.icofont-phone {
  &:before {
    content: $icofont-phone;
  }
}
.icofont-alarm {
  &:before {
    content: $icofont-alarm;
  }
}
.icofont-settings {
  &:before {
    content: $icofont-settings;
  }
}
.icofont-settings-alt {
  &:before {
    content: $icofont-settings-alt;
  }
}
.icofont-plus {
  &:before {
    content: $icofont-plus;
  }
}
.icofont-options {
  &:before {
    content: $icofont-options;
  }
}
.icofont-tools-alt-2 {
  &:before {
    content: $icofont-tools-alt-2;
  }
}

.icofont-xs {
  font-size: 0.5em;
}

.icofont-sm {
  font-size: 0.75em;
}

.icofont-md {
  font-size: 1.25em;
}

.icofont-lg {
  font-size: 1.5em;
}

.icofont-1x {
  font-size: 1em;
}

.icofont-2x {
  font-size: 2em;
}

.icofont-3x {
  font-size: 3em;
}

.icofont-4x {
  font-size: 4em;
}

.icofont-5x {
  font-size: 5em;
}

.icofont-6x {
  font-size: 6em;
}

.icofont-7x {
  font-size: 7em;
}

.icofont-8x {
  font-size: 8em;
}

.icofont-9x {
  font-size: 9em;
}

.icofont-10x {
  font-size: 10em;
}

.icofont-fw {
  text-align: center;
  width: 1.25em;
}

.icofont-ul {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}

.icofont-ul > li {
  position: relative;
  line-height: 2em;
}

.icofont-ul > li .icofont {
  display: inline-block;
  vertical-align: middle;
}

.icofont-border {
  border: solid 0.08em #f1f1f1;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.icofont-pull-left {
  float: left;
}

.icofont-pull-right {
  float: right;
}

.icofont.icofont-pull-left {
  margin-right: 0.3em;
}

.icofont.icofont-pull-right {
  margin-left: 0.3em;
}

.icofont-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icofont-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icofont-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icofont-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icofont-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icofont-flip-horizontal.icofont-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .icofont-rotate-90,
:root .icofont-rotate-180,
:root .icofont-rotate-270,
:root .icofont-flip-horizontal,
:root .icofont-flip-vertical {
  -webkit-filter: none;
  filter: none;
  display: inline-block;
}

.icofont-inverse {
  color: #fff;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
