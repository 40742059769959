.btn-default {
  cursor: pointer;
  border-radius: .25rem;
  background-color: $col-darkblue;
  color: #FFFFFF;
  font-weight: 600;
  padding: .75rem;
  width:100%;
  text-transform: uppercase;
  display: block;
  text-align: center;
}

.btn-submit {
  @extend .btn-default;
  background-color: $col-green;
  color: #000000;
}

.btn-warning {
  @extend .btn-default;
  background-color: $col-red;
}

.btn-green {
  @extend .btn-submit;
}
