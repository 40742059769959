.accordion {
  .accordion__item {
    .accordion__heading {
      border-bottom: 1px solid $col-lightgrey;
      margin-bottom: 0;
      color: $col-darkblue;
      font-size: 16px;
      font-weight: 700;
      padding: .75rem;
      box-sizing: border-box;
      padding-left: 24px;
      position: relative;
      cursor: pointer;

      .infosCount {
        background-color: $col-red;
        color: #fff;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-align: center;
      }

      .accordion__button:focus {
        box-shadow: none;
      }

      .accordion__button[aria-expanded="true"] .icofont:before,
      .accordion__button[aria-selected="true"] .icofont:before {
        content:$icofont-simple-up
      }
    }

    .accordion__panel {
      background-color: $col-lightblue;
      padding-left: 24px;
      padding-bottom: 20px;

      .infoContent {
        padding-top: 20px;

        .borderLeft {
          border-left: 3px solid $col-darkblue;
          padding-left: 15px;
        }

        p {
          margin-bottom: 5px;
          color: $col-darkblue;
          font-size: 16px;

          &.contenu {
            font-weight: 600;
          }

          &.adresse {

            .marker {
              margin-right: 6px;
              position: relative;
              top: -2px;
            }
          }
        }
      }
    }
  }
}
