@mixin font($name, $url, $weight: normal, $style: normal) {
    @font-face {
      font-family: $name;
      font-style: $style;
      font-weight: $weight;
      src: url($font-path + $url + ".eot"); /* IE9 Compat Modes */
      src: url($font-path + $url + ".eot") format('embedded-opentype'), /* IE6-IE8 */
      url($font-path + $url + ".woff2") format('woff2'), /* Super Modern Browsers */
      url($font-path + $url + '.woff') format('woff'), /* Pretty Modern Browsers */
      url($font-path + $url + ".ttf") format('truetype'), /* Safari, Android, iOS */
      url($font-path + $url + ".svg#" + $name) format('svg'); /* Legacy iOS */
    }
}
