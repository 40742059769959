.notification_group {
  padding-top: 35px;
  .notifications {
    margin-bottom: 15px;
    input[type="checkbox"] {
      display: none;
      &:checked ~ .bg-check img {
        display: block;
      }
    }
    .notification_label {
      display: block;
      float: left;
      padding-left: 5px;
      width: calc(100% - 25px);
      line-height: 1.3em;
      font-size: 14px;
      font-weight: 600;
      color: $col-darkblue;
    }
    .bg-check {
      border: 1px solid $col-darkblue;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      display: block;
      float: left;
      position: relative;
      top: 1px;
      > img {
        position: absolute;
        top: 3px;
        left: 2px;
        display: none;
      }
    }
  }
}
