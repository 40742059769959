.InfosListe {
  list-style-type: none;

  li {
    border-bottom: 1px solid $col-lightgrey;
    margin-bottom: 0;

    a {
      color: $col-darkblue;
      font-size: 16px;
      font-weight: 700;
      height: 100%;
      padding: 12px 0;
      box-sizing: border-box;
      display: block;
    }
  }
}
.infoContent {
  .infoContentItem {
    color: $col-darkblue;
    padding: .5rem 0;
    .icofont {
      margin-right: .375rem;
    }
  }
}


ul.defaultListe {
  list-style-type: none;

  li {
    border-bottom: 1px solid $col-lightgrey;
    margin-bottom: 0;

    .listeItem {
      color: $col-darkblue;
      font-size: 16px;
      font-weight: 700;
      padding: 12px 0 12px 25px;
      box-sizing: border-box;
      display: block;
      &.logoutButton {
        color: $col-red;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        width: 100%;
        text-align: center;
        padding: 12px 0;
        box-sizing: border-box;
        display: block;
      }
    }
    &.listeEmpty {
      height: 49px;
      content: ' ';
    }

  }
}
