.selectTheme {
  width: 100%;
  box-shadow: none;
  border-bottom: 2px solid $col-darkblue;
  background-color: $col-lightblue;
  margin-top: 20px;
  margin-bottom: 25px;
  .basic-multi-select {
    .select__control {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      box-shadow: none !important;
      padding: .375rem;
      .select__placeholder {
        color: $col-darkblue;
        font-weight: 600;
        font-size: 14px;
      }
      .select__multi-value {
        background-color:rgba(0, 82, 204, 0.1);

        .select__multi-value__label {
          color: #000000;
          padding: 0 6px;
        }
        .select__multi-value__remove {
          color: #000000;
          padding:0 8px;
          &:hover {
            background-color: transparent;
            cursor: pointer;
          }
        }
      }
    }
  }
  .select__menu {
    margin: 0;
    background-color: $col-lightblue;
    z-index: 9999;
  }
  .select__value-container {
    padding: 0;
    line-height: 33px;
  }
  #react-select-2-input {
    margin: 0;
  }
}

.select__option--is-focused {
  background-color: darken($col-lightblue, 10%) !important;
}

.select__option--is-selected {
  background-color: darken($col-lightblue, 20%) !important;
  color: $col-darkgrey !important;
}
